<template>
  <v-card class="py-8 px-8">
    <v-window v-model="step">
      <v-window-item :value="1">
        <Headline />
        <v-form class="login-form" @submit.prevent="login">
          <!-- if we have response => errors -->
          <v-alert v-if="errors" type="error">
            <div v-for="(value, key) in errors" :key="key">
              <div v-for="error in value" :key="error">
                {{ error }}
              </div>
            </div>
          </v-alert>
          <!-- if we have response => error message -->
          <v-alert v-else-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <v-text-field
            class="text-field"
            data-matomo-mask
            id="username"
            v-model="username"
            :rules="[value => rules.required(value) || this.$t('auth.username_required')]"
            append-icon="mdi-account-outline"
            autocomplete="off"
            label="Username"
            required
            filled
            dense
          />
          <v-text-field
            id="password"
            data-matomo-mask
            v-model="password"
            :rules="[value => rules.required(value) || this.$t('auth.password_required')]"
            append-icon="mdi-lock-outline"
            autocomplete="off"
            label="Password"
            required
            type="password"
            filled
            dense
          />
          <div class="text-center mb-4 button">
            <PrimaryButton :disabled="submittedForm" class="login-button" type="submit">{{ $t('auth.login') }}</PrimaryButton>
          </div>
        </v-form>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import { rules } from '@/validation/rules'
import Headline from '@/components/app/Headline'
import PrimaryButton from '@/components/app/PrimaryButton'
import Logger from '@/util/Logger'
import LocalStore from '@/util/LocalStore'

export default {
  components: {
    Headline,
    PrimaryButton
  },
  data() {
    return {
      rules: { ...rules },
      username: '',
      email: '',
      password: '',
      submittedForm: false,
      errorMessage: null,
      errors: null,
      step: 1
    }
  },
  methods: {
    login: function() {
      LocalStore.logout()

      this.errorMessage = null
      this.errors = null
      this.submittedForm = true
      this.$store
        .dispatch('auth/loginUser', {
          username: this.username,
          password: this.password
        })
        .then(() => {
          let url = this.$route.query.redirect
          if (url) {
            Logger.log('[Login] redirecting to:', url)
            this.$router.push({ path: url.toString() })
          } else {
            this.$router.push({ path: '/dashboard' })
          }
        })
        .catch(error => {
          this.submittedForm = false
          if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
            if (typeof error.response.data.errors !== 'undefined') {
              return (this.errors = error.response.data.errors)
            } else {
              return (this.errorMessage = error.response.data.message)
            }
          } else if (typeof error === 'object') {
            return (this.errorMessage = '' + error)
          }
        })
    }
  },
  mounted: function() {
    // Check if login as user from old prokura is allowed
    if (typeof this.$route.query.userlogin !== 'undefined' && this.$route.query.userlogin.indexOf('loginas:') !== -1) {
      let explodeQuery = this.$route.query.userlogin.split(':')
      this.errors = null
      this.$store
        .dispatch('auth/loginUser', {
          username: explodeQuery[2],
          password: this.$route.query.userlogin
        })
        .then(() => {
          this.$router.push({ path: '/' })
        })
        .catch(error => {
          if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
            return (this.errorMessage = typeof error.response.data.errors !== 'undefined' ? error.response.data.errors : error.response.data.message)
          } else if (typeof error === 'object') {
            return (this.errorMessage = '' + error)
          }
        })
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#login
  max-width: 560px
  margin: 64px auto

  .v-form
    width: auto
</style>
