<template>
  <div id="login">
    <AuthLogin />
  </div>
</template>

<script>
import AuthLogin from '../../components/auth/AuthLogin'

export default {
  components: {
    AuthLogin
  }
}
</script>
